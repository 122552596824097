import React, { useState, useEffect } from 'react';
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Image,
  Link,
} from "@chakra-ui/react";
import firebase from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ReactComponent as SSValueProp } from './images/SqaurespaceSproutLogin.svg';
import CustomSproutButton from './CustomSproutButton';
import { getUrlParams } from '../utils';
import posthog from 'posthog-js';

async function handleSignIn(email, password, setError, setLoading) {
  setLoading(true);
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    posthog.capture('successful login');
  } catch (error) {
    setError(error.message || "Invalid username or password");
    window.setTimeout(() => { setError("") }, 3000);
  } finally {
    setLoading(false);
  }
}

export default function Login() {
  const location = useLocation();
  const stateData = location.state;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [user, loading] = useAuthState(firebase.auth());
  const nav = useNavigate();
  const params = getUrlParams();

  useEffect(() => {
    posthog.capture('page view: login');
  }, []);

  useEffect(() => {
    if (!loading) {
      if (user) {
        nav("/home", {
          state: { ...params, ...stateData },
        });
      }
    }
  }, [loading, user]);

  const handleCreateAccountClick = () => {
    posthog.capture('click: create account');
  };

  const handleLoginClick = () => {
    posthog.capture('click: log in');
    handleSignIn(email, password, setError, setLoading);
  };

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex flex={1} position="relative">
        <Image
          objectFit="cover"
          src="https://sprout-trees.imgix.net/standalone-login-banner.jpeg?fm=webp&auto=format&w=1000&blur=41"
        />
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <SSValueProp />
        </Box>
      </Flex>

      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack w={"full"} maxW={"md"}>
          <Box>
            <Image
              mb="60px"
              h="35px"
              src="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/6417bf52d0396940659d64d3_logo-sprout-green.svg"
            />
          </Box>
          <Text mb="30px" fontSize="xl" fontWeight="medium">
            Log in to your Sprout account
          </Text>

          <Box mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              mb={4}
              bgColor="white"
              borderRadius="10"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              mb={4}
              bgColor="white"
              borderRadius="10"
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Stack spacing={6}>
            <Link href="/forget_password" color={"teal.500"} align="right">
              Forgot password?
            </Link>

            <CustomSproutButton
              ignoreToast={true}
              color={"teal"}
              label={isLoading ? "Signing in..." : "Sign into Sprout account"}
              handler={handleLoginClick}
              ignoreBillingModal={true}
              isLoading={isLoading}
            />
            {error && <Text color="red">{error}</Text>}
            <center>
              <Text>
                Don’t have an account?{" "}
                <Link as={ReactLink} state={stateData} to="/signup" fontWeight="medium" color={"teal.500"} onClick={handleCreateAccountClick}>
                  Sign Up for Sprout account
                </Link>
              </Text>
            </center>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
}
