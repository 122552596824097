import React, { useState } from 'react';
import firebase from '../firebase';
import { Navigate, Link as ReactLink, useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {ReactComponent as SSValueProp} from './images/SqaurespaceSproutLogin.svg';
import {getUrlParams} from '../utils';
import { useAuthState } from 'react-firebase-hooks/auth';

import {
  Button,
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Image 
} from "@chakra-ui/react";

export const CreateAccount = () => {

  const params = getUrlParams();
  const location = useLocation();
  let stateData = location.state;
  if (!stateData) {
    stateData = {};
  }


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, loading] = useAuthState(firebase.auth());
  const nav = useNavigate();

  React.useEffect(() => {
    if (!loading) {
      if (user) {
        return nav("/home", {
         state:{...params, ...stateData},
        }, );
      }
    }
  }, [loading, user]);


  const signUpWithEmail = async () => {

    try {
      if (password !== confirmPassword) {
        throw new Error('Passwords do not match');
      }
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      var resp = await firebase.auth().signInWithEmailAndPassword(email, password);
      setIsLoggedIn(true);

    } catch (error) {
      setError(error.message);
    }
  };




  if (isLoggedIn) {
    return <Navigate to="/home" state={{...params, ...stateData}} />;
  }
  

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
<Flex flex={1} position="relative">
  <Image
    objectFit="cover"
    src="https://sprout-trees.imgix.net/standalone-login-banner.jpeg?fm=webp&auto=format&w=1000&blur=41"
  />
  <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
    <SSValueProp />
  </Box>
</Flex>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack w={"full"} maxW={"md"}>
          <Box>
            <Image
              mb="60px"
              h="35px"
              src={
                "https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/6417bf52d0396940659d64d3_logo-sprout-green.svg"
              }
            />
            <Text mb="30px" fontSize="xl" fontWeight="medium">
              Create an account
            </Text>
          </Box>

          <Box mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              mb={4}
              bgColor="white"
              borderRadius="10"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              mb={4}
              bgColor="white"
              borderRadius="10"
              placeholder="Enter password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              mb={4}
              bgColor="white"
              borderRadius="10"
              placeholder="Confirm password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Box>
          <Stack spacing={6}>
            <Button colorScheme={"teal"} variant={"solid"} onClick={signUpWithEmail}>
              Create a Sprout account
            </Button>
            {error && <Text color="red">{error}</Text>}
            <center>
              <Text>
                Already have an account?{" "}
                
                <Link to="/login" as={ReactLink} state={{...params, ...stateData}} fontWeight="medium" color={"teal.500"}>
                  Login
                </Link>
              
              </Text>
            </center>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
};
