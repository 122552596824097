
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure, } from '@chakra-ui/react'

import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    } from "@chakra-ui/react"
import { HTTPPost } from '../httputils';



function SuccessModal({show=false}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const nav = useNavigate();
    React.useEffect(() => {
        if (show) {
            onOpen()
      
        window.setTimeout(() => {
            onClose()
            nav("/login", )

            


        }, 60000)
    }
    }, [show])
  
    return (
        <>
        <Modal isOpen={isOpen} onClose={function(){
            
            onClose()
            nav("/login", )


        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Connecting Integration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
    <div>
        <p><strong>Success!</strong></p>
        <br />
        <p>Successfully connected your store to Sprout. Here are some tips to get started:</p>
        <br />
        <ul>
            <li>
                <strong>Select when to plant trees:</strong> Choose when and how many trees to plant per order under the app settings tab. 🌳
            </li>
            <li>
                <strong>Install Banners:</strong> Show your sustainability initiatives by installing banners with a click of a button under the banners tab. 🎉
            </li>
            <li>
                <strong>Certificates and Marketing Assets:</strong> Receive a monthly certificate verifying the previous month's planting. Please also contact us for additional photos and videos if you'd like for marketing ❤️
            </li>
        </ul>
        <img src="https://sprout-trees.imgix.net/success-model-img.png?fm=webp&auto=format&w=1000" alt="Planting photo" />
    </div>
</ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

        </>
    )
}

export function AccessDeniedModal({show=false}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const nav = useNavigate();
    React.useEffect(() => {
        if (show) {
            onOpen()
        }
    }, [show])
  
    return (
        <>
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Connecting Integration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
  <div>
    <p>
      <strong>Access Denied</strong>
    </p>
    <br />
    <p>
      <em>Failed to connect the integration.</em> Access to your site is needed to integrate with Sprout. Please try again or contact us at saif@thegoodapi.com for further assistance.
    </p>
    <br />
    <img src="https://sprout-trees.imgix.net/kenya-planting-photo.jpeg?fm=webp&auto=format&w=1000" alt="Kenya Planting" />
  </div>
</ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

        </>
    )
}

export default function IntegrationModal({show, refComp, oauthStateData, user}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showSuccessModal, setSuccessModal] = useState(false)
    const [showErrorModal, setErrorModal] = useState(false)
    const nav = useNavigate();
    React.useEffect(() => {
    if (show) {
        onOpen()
    }
    }, [show])

    
    
    React.useEffect(() => {
    const n = async () => {
        if (show) {
            if (oauthStateData['error'] == "access_denied") {
               onClose()
                setErrorModal(true)
                // clear state
                window.history.replaceState({}, document.title)
                return 

            }


            var connectionResp = await HTTPPost(oauthStateData['redirectPath'], user.getIdToken(true), {
                "integrationId": oauthStateData['integrationId'],
                "state": oauthStateData['state'],
                "code": oauthStateData['code'],
                "error": oauthStateData['error'],
            })

           

            if (connectionResp['status'] != 200) {
               onClose()
            //    setErrorModal(true)
            //    // clear state
            //    window.history.replaceState({}, document.title)
               return  nav("/login", )
            }

            setSuccessModal(true)
        }


        
        onClose()

        
    }
    n()
    }, [show])



    return (
        <>
        <AccessDeniedModal show={showErrorModal} />
        <SuccessModal show={showSuccessModal} />
        <Modal closeButton={false} closeOnEsc={false} closeOnOverlayClick={false} finalFocusRef={refComp} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Connecting Integration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <div>
            <p>Connecting with {oauthStateData?  oauthStateData['integrationId'] : "third party integration..." }....</p>
           </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

        </>
    )
}
