import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
var config = {
  apiKey: "AIzaSyAUERtpgIP95xm2NLVEoLkzOH7PGi-6L5o",
  authDomain: "sprout-standalone.firebaseapp.com",
  projectId: "sprout-standalone",
  storageBucket: "sprout-standalone.appspot.com",
  messagingSenderId: "739223026498",
  appId: "1:739223026498:web:7ff8d34ee527fcd269a6db",
  measurementId: "G-4M6CDY8PPN"
};

const app = firebase.initializeApp(config);
const analytics = firebase.analytics(app);
console.log("Analyitcs started", analytics);

(async () => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
})();

const auth = firebase.auth();
auth.onAuthStateChanged(user => { 
  if (user) {
    console.log('user logged in');
  } else {
    console.log('user logged out');
  }
});



export default firebase;