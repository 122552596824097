import { HTTPPost } from './httputils'
export async function connectIntegration(user, redirectPath, integrationId, urlParams) {
try{
    const jwt = await user.getIdToken(true)
    var resp = await HTTPPost(redirectPath, jwt, {
        integrationId: integrationId, 
        state: urlParams.state, 
        code: urlParams.code, 
        error: urlParams.error
    })
    return resp[0]
} catch (e) {
    return 500
}
}


export function getUrlParams() {
  const urlParams = {};
  const queryString = window.location.search.slice(1);

  queryString.split('&').forEach((param) => {
    const [key, value] = param.split('=');
    urlParams[key] = decodeURIComponent(value);
  });

  return urlParams;
}
