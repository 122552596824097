import React from 'react'
import { Button } from '@chakra-ui/react'
import BillingModal from './BillingModal';
import { useToast } from "@chakra-ui/react";

export default function CustomSproutButton({
    compStore, setCompStore,
    label, color, handler, ignoreBillingModal=false, 
    toastSuccess="Success",
    ignoreToast=false,
    isDisabled=false,

    toastError="Something went wrong, please contact saif@thegoodapi.com and let our team know which screen caused this error. Thank you 🌳"},
    ) {

        const toast = useToast()
        const [showBillingModal, setShowBillingModal] = React.useState(false);
        const [isLoading, setIsLoading] = React.useState(false)

        return( 
            <>
            <BillingModal show={showBillingModal} setShow={setShowBillingModal} compStore={compStore} setCompStore={setCompStore}  />
             <Button 
             size="lg"
             spinnerPlacement="end"
             isLoading={isLoading}
             isDisabled={isDisabled}
             colorScheme={color} 
             onClick={async ()=>{
                    if (compStore?.global_account_settings?.billing?.status === "active" || ignoreBillingModal) {
                        setIsLoading(true)
                        try {
                        await handler()
                        if (!ignoreToast) {

                        toast({
                            title: "Success",
                            description: toastSuccess,
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                        })
                    }
                    
                        } catch (e) {
                            if (!ignoreToast) {
                            toast({
                                title: "Error",
                                description: toastError,
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        

                        }
                        setIsLoading(false)
                        return
                    } else {
                        setShowBillingModal(true)
                        return
                    }

             }}>{label}</Button>
            </>
        )



    }