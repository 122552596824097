
import React from "react";
import { Alert, AlertIcon, AlertDescription, Link, Text } from "@chakra-ui/react";
import BillingModal from "./BillingModal";

export default function BillingInfoAlert({compStore, setCompStore, helperText="To activate Sprout, input billing information =)"}) {
  const [showBillingModal, setShowBillingModal] = React.useState(false);
  if (compStore.global_account_settings?.billing?.status === "active") {
    return null
  }

  return (
    <>
    <BillingModal show={showBillingModal} setShow={setShowBillingModal} compStore={compStore} setCompStore={setCompStore} />
    <Alert status="error">
    <AlertIcon />
    <AlertDescription>
      <b>Enter billing information.</b> To get Started with Sprout, you'll need to first setup your <span></span>
      <Link onClick={()=>{
        setShowBillingModal(true)
      }} textDecoration="underline">
      billing information 
      </Link>
    </AlertDescription>

  </Alert>
  </>
  )

}