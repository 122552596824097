import firebase from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {Navigate, useNavigate} from 'react-router-dom';
import {getUrlParams} from '../utils';
import React from 'react';


export default function InitiateSS() {
    const params = getUrlParams();
    const [user, loading] = useAuthState(firebase.auth());
    const nav = useNavigate();

    return <Navigate to="/signup" state={params} />
}