import React, { useEffect } from 'react';

import firebase from '../firebase';

import {
  Collapse,
  AlertDescription,
  AlertIcon,
  Link,
    Box,
    Flex,
    Container,
    Stack,
    Text,
    Image,
    Heading,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Radio,
    RadioGroup,
    Button,
    Badge,
    Checkbox,
    CheckboxGroup,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Alert,
    useDisclosure
  } from "@chakra-ui/react";
  import { Fade, ScaleFade, Slide, SlideFade } from "@chakra-ui/react"
import BillingModal from './BillingModal';
import ButtonWithDialog from './ButtonWithDialog';
import ToggleButton from './ToggleButton';
import { UninstallProductBadge,GetSproutProductBadgeRender, InstallProductBadge, LoadBadgeCSS, LoadModalScript, GetAccountSettings, RefreshSettings } from '../backend';
import CustomSproutButton from './CustomSproutButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import CounterBanner from './CounterBanner';


export default function CollapseButton({compStore, setCompStore}) {
    const { isOpen, onToggle } = useDisclosure()
    const [isCopied, setCopied] = React.useState(false);
    const handleCopyClick = () => {
      const codeToCopy = `<script src="https://sprout-api.thegoodapi.com/ss/scripts/js/impact_counter.js?integration_id=${compStore.current_integration.uuid}"></script>\n\n<div id="SproutTreeCountVintage"></div>`;
      
      navigator.clipboard.writeText(codeToCopy)
        .then(() => setCopied(true))
        .catch((err) => console.error('Unable to copy to clipboard', err));
    };
    
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }

    let alertText = "Badges will not install until the app settings and billing are configured"
    if (compStore.current_integration?.status == "active") {
      alertText = ""     
    }



    function counterInstallButton() {
      return (
        <>
           {alertText != ""? (
          <>
          <br/>
          <br/>
        <Alert status="error">
  <AlertIcon />
  <AlertDescription>{alertText}</AlertDescription>
</Alert>
<br/>
  </>
        ):(<></>)}
       <CustomSproutButton
       ignoreBillingModal={false}
       ignoreToast={true}
          compStore={compStore} setCompStore={setCompStore}
          label={"Install Instructions"}
          color={"teal"}
          handler={
            async () => {
              onToggle() 
            }
            
          }
        />
        {isOpen? (
          <Fade in={isOpen}>
          <Box
            p="40px"
            color="white"
            mt="4"
            bg="white"
            rounded="md"
            shadow="md"
          >
            <Box mb={1}>
              <Text fontSize="xl" fontWeight="medium" mb={2} textColor={"black"}>
                Real Time Impact Counter Installation
              </Text>
              <Text lineHeight="100%" mb={8} textColor="black">
                To install the counter on your home page (or any page you'd like) all you'll want to do is the following instructions or video:
                <br/><br/>
          
      <Box
        position="relative"
        paddingBottom="64.67065868263472%"
        height={0}
      >
        <iframe
          title="Video"
          src="https://www.loom.com/embed/04ad7b20d2c54db9b40fbbbfb768f2fa?sid=c4449fc5-fe7b-40fc-b24d-2adb78cf1afd"
          frameBorder="0"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </Box>


                <br/>
                <br/>
                <b>1. </b> In your Squarespace editor, click add "section", select a blank section and in the section click "+ Add Block" and select "Code" from the menu.
                <br/>
                <br/>
                <b>2. </b> In the code section, select HTML and set the "display source code" toggle to "off"
                <br/>
                <br/>
                <b>3. </b> Paste the code snippet into the code section and click "Save" on the top left. Make sure to center the block
                <br/>
                <br/>
                {/* code snipper */}
                <Box bgColor={"gray.100"} p={4} borderRadius={4}>
                  <Flex>
                <Text fontSize={"sm"} color={"gray.500"}>
        &lt;script src="https://sprout-api.thegoodapi.com/ss/scripts/js/impact_counter.js?integration_id={compStore.current_integration.uuid}"&gt;&lt;/script&gt;<br/>
        &lt;div id="SproutTreeCountVintage"&gt;&lt;/div&gt;
      </Text>
      
      <Button  onClick={handleCopyClick}>
        {isCopied ? 'Copied!' : 'Copy to Clipboard'}
      </Button>
      </Flex>
                </Box>

              </Text>
              </Box>
          </Box>
        </Fade>
        ):(<></>)}

        </>
      )
    }

    return (
        <>
        {counterInstallButton()}
        </>
    )
  
  }
  