import React, { useEffect } from 'react';

import firebase from '../firebase';

import {
  Collapse,
  AlertDescription,
  AlertIcon,
  Link,
    Box,
    Flex,
    Container,
    Stack,
    Text,
    Image,
    Heading,
    
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Radio,
    RadioGroup,
    Button,
    Badge,
    Checkbox,
    CheckboxGroup,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Alert,
    useDisclosure
  } from "@chakra-ui/react";
import BillingModal from './BillingModal';
import { AspectRatio } from '@chakra-ui/react'
import ButtonWithDialog from './ButtonWithDialog';
import ToggleButton from './ToggleButton';
import { UninstallProductBadge,GetSproutProductBadgeRender, InstallProductBadge, LoadBadgeCSS, LoadModalScript, GetAccountSettings, RefreshSettings } from '../backend';
import CustomSproutButton from './CustomSproutButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import CounterBanner from './CounterBanner';
import CollapseButton from './CollapseButton';
import { Fade, ScaleFade, Slide, SlideFade } from "@chakra-ui/react"


export default function Banners({compStore, setCompStore}) {
    const [user, loading] = useAuthState(firebase.auth())
 
    const [badgeRender, setBadgeRender] = React.useState(null)
    async function getSproutProductBadgeRender() {
        await LoadBadgeCSS()
        await LoadModalScript("https://sprout-app.thegoodapi.com/app/badges/product_modal_script", compStore.current_integration.uuid)
        const badgeRender = await GetSproutProductBadgeRender(user.getIdToken(true),compStore.current_integration?.uuid)
        if (badgeRender == null) {
          setBadgeRender("<div> Setup your app settings, before seeing what the badge will look like </div>")
          return
        }



        setBadgeRender(badgeRender)
    }
    
    const [showBillingModal, setShowBillingModal] = React.useState(false);



    function saveButton() {
      var label = "Install on my site"
      var currentBadgeStatus = "inactive"
      for (var i = 0; i < compStore.current_integration?.banner_settings?.length; i++) {
        var bs = compStore.current_integration?.banner_settings[i]
         if (bs.type == "product" && bs.status == "active") {
          label = "Uninstall from my site"
          currentBadgeStatus = bs.status
      }
    }
     

    let alertText = "Badges will not install until the app settings are configured"
     if (compStore.current_integration?.status =="active") {
       alertText = ""
      
     }

      return (
<>
        <CustomSproutButton toastSuccess={currentBadgeStatus == "active"? "Badges uninstalled": "Badge installed on product pages"} 
        toastError={compStore.current_integration?.status !="active"? "Configure your app settings before installing the badges": "Error installing badges, please contact saif@thegoodapi.com for support"}
        
        compStore={compStore} setCompStore={setCompStore} label={label} color ={"teal"} handler={
          async () => {
            if (compStore.current_integration?.status !="active") {
              alert("Configure your app settings before installing the badges")
              throw new Error("Configure your app settings before installing the badges")
            }
            if (currentBadgeStatus == "active") {
              await UninstallProductBadge(user.getIdToken(true),compStore.current_integration?.uuid)
              await RefreshSettings(compStore.current_integration.uuid, compStore, setCompStore, user.getIdToken(true),)
              return
            }
           await InstallProductBadge(user.getIdToken(true),compStore.current_integration?.uuid)
           await RefreshSettings(compStore.current_integration.uuid, compStore, setCompStore, user.getIdToken(true),)
           return

                        
          }
        } />
        {alertText != ""? (
          <>
          <br/>
          <br/>
        <Alert status="error">
  <AlertIcon />
  <AlertDescription>{alertText}</AlertDescription>
</Alert>
  </>
        ):(
          <></>
        )}



</>


      )
    }

   

    React.useEffect( () => {
       getSproutProductBadgeRender()
      
    }, [])


    React.useEffect( () => {
      getSproutProductBadgeRender()

    }, [compStore])

    if (!loading && !user) {
      return <Navigate to="/login" />
  }


    return (
        <>
        {showBillingModal? (
            <BillingModal show={showBillingModal} setShow={setShowBillingModal} compStore={compStore} setCompStore={setCompStore} />
        ):(
            <></>
        )

            
          }
   <Flex
        flexDirection={{ base: "column", md: "row", lg: "row" }}
        gap="6"
      >
        <Box w={{ base: "100%", md: "80%", lg: "80%" }}>
          <Box
            mb={0}
            bgColor="#F4F8FB"
            borderRadius="24"
            p={{ base: "4", md: "8", lg: "8" }}
          >
            <Box mb={6}>
              <Text fontSize="xl" fontWeight="medium" mb={2}>
                Product page banner
              </Text>
              <Text lineHeight="150%" mb={8} textColor="gray.500">
                Let your customers know how their purchase will make a
                positive impact. Add an impact banner on all your product
                pages to drive conversion and environmental impact.
              </Text>
            </Box>
            <Box mb={8}>
              <FormLabel fontWeight="medium">Banner preview</FormLabel>
              <Box>
                <Box>
                <Flex alignItems="center">
      <Box marginLeft="5px" dangerouslySetInnerHTML={{ __html: badgeRender }} />
      {/* <ClickableArrow /> */}

    </Flex>
                </Box>
              </Box>
              <Image
                src="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/640fe4bcac6ee353b72ec71b_ss-banner.png"
                alt="Product Banner Example"
              />
            </Box>
            <Box mb={6}>
             
              <Text color="gray.500" mb={4}>
                The product badge will be <b>auto installed</b> into your theme 🎉
              </Text>
            </Box>
          {/* Shift button left */}
            {saveButton()} 
          </Box>
        </Box>

      </Flex>

<br/>
      <Flex
        flexDirection={{ base: "column", md: "row", lg: "row" }}
        gap="6"
      >
        <Box w={{ base: "100%", md: "80%", lg: "80%" }}>
          <Box
            mb={0}
            bgColor="#F4F8FB"
            borderRadius="24"
            p={{ base: "4", md: "8", lg: "8" }}
          >
            <Box mb={6}>
              <Text fontSize="xl" fontWeight="medium" mb={2}>
                Real Time Impact Counter
              </Text>
              <Text lineHeight="150%" mb={8} textColor="gray.500">
                Let your customers know how their purchase will make a
                positive impact. Add an impact banner on your homepage and let your customers know how many trees they've planted with their purchases.
              </Text>
            </Box>
            <Box mb={8}>
              <FormLabel fontWeight="medium">Banner preview</FormLabel>
              <Box>
                <Box>
                <Flex alignItems="center">
                <CounterBanner compStore={compStore} setCompStore={setCompStore} />

      {/* <ClickableArrow /> */}


    </Flex>
                </Box>
              </Box>
             
            </Box>
            <Box mb={6}>
            </Box>
            {/* Install instructions */}
          {/* Shift button left */}
           <CollapseButton compStore={compStore} setCompStore={setCompStore} />


          </Box>
        </Box>

      </Flex>

      
        </>
    )
}