import firebase from "./firebase"

import 'firebase/compat/remote-config';


const baseURL = "https://sprout-api.thegoodapi.com"

const HTTPPost = async (url, auth, data) => {
    if (Promise.resolve(auth) == auth ){
        auth = await auth
    }
    const resp = await fetch(`${baseURL}${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': auth,
        },
        body: JSON.stringify(data)
        })

    if (resp.status != 200) {
        return {
            status: resp.status,
            data: null
        }
    }

    try {
    var json = await resp.json()
    return {
        status: resp.status,
        data: json
    }
} catch (e) {
    return {
        status: resp.status,
        data: null
    }
}
}


export const HTTPDelete = async (url, auth, data) => {
    if (Promise.resolve(auth) == auth ){
        auth = await auth
    }
    const resp = await fetch(`${baseURL}${url}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': auth,

        },
        body: JSON.stringify(data)
        })

    if (resp.status != 200) {
        return {
            status: resp.status,
            data: null
        }
    }

    try {

    var json = await resp.json()
    return {
        status: resp.status,
        data: json
    }
} catch (e) {
    return {
        status: resp.status,
        data: null
    }
}
}

const HTTPGet = async (url, auth, params) => {
    if (Promise.resolve(auth) == auth ){
        auth = await auth
    }
    const queryParams = new URLSearchParams(params).toString();
    const requestUrl = `${baseURL}${url}?${queryParams}`;
    try {
    var resp = await fetch(requestUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': auth,

        }
    })

    if (resp.status != 200) {
        return {
            status: resp.status,
            data: null
        }
    }

    var json = await resp.json()
    return {
        status: resp.status,
        data: json,
    }
} catch (e) {
    return {
        status: 500,
        data: null
    }
}
}


const HTTPGetText = async (url, auth, params) => {
    if (Promise.resolve(auth) == auth ){
        auth = await auth
    }
    const queryParams = new URLSearchParams(params).toString();
    const requestUrl = `${baseURL}${url}?${queryParams}`;
    try {
    var resp = await fetch(requestUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': auth,

        }
    })

    if (resp.status != 200) {
        return {
            status: resp.status,
            data: null
        }
    }

    var json = await resp.text()
    return {
        status: resp.status,
        data: json,
    }
} catch (e) {
    return {
        status: 500,
        data: null
    }
}
}

export { HTTPGet, HTTPPost, HTTPGetText }