
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure, } from '@chakra-ui/react'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,

    } from "@chakra-ui/react"
import { HTTPPost } from '../httputils';
import Billing from './Billing';


export default function BillingModal({show, setShow, compStore, setCompStore}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showSuccessModal, setSuccessModal] = useState(false)
    const nav = useNavigate();
    React.useEffect(() => {
    if (show) {
        onOpen()
    }
    }, [show])

    return (
        <>
        <Modal size="4xl" onCloseComplete={()=>{setShow(false)}} closeButton={false} closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Billing</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          
          <Billing compStore={compStore} setCompStore={setCompStore}/>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

        </>
    )
}
