import firebase from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
 import {Navigate} from 'react-router-dom';


export default function OauthConnect({redirectPath, integrationId}) {
    const [user, loading] = useAuthState(firebase.auth());

    const urlParams = {
        code: new URLSearchParams(window.location.search).get('code'),
        state: new URLSearchParams(window.location.search).get('state'),
        error: new URLSearchParams(window.location.search).get('error'),
    }
    const state = {
        redirectPath: redirectPath,
        integrationId: integrationId,
        state: urlParams.state,
        code: urlParams.code,
        error: urlParams.error,
        stateType: "oauth",
    }

   

    if (!loading && !user) {
       return <Navigate to="/login" state={state} />
    }

    return <Navigate to="/home" state={state} />
}