import {
    Box,
    Flex,
    Text,
    Badge,
    Alert,
    AlertIcon,
    AlertDescription,
    SimpleGrid,
    Heading,
    Link,
    Spacer
    } from "@chakra-ui/react";

export default function Metrics({compStore, SetCompStore}){
    

  function statusBanner() {
    if(compStore.current_integration?.status == "active"){
      return(
        <Badge
        py="4px"
        px="8px"
        borderRadius="16px"
        colorScheme="green"
        Variant="subtle"
       
       >
        Active
       </Badge>
      )
    } else {
       return (        
            <Badge
            py="4px"
            px="8px"
            borderRadius="16px"
            colorScheme="red"
            Variant="subtle"
        
          >
            Inactive
          </Badge>
    
        )
    }
    
  
  }



    return (
        <Box my={6}>
  <Flex>
    <Text fontSize="5xl">
      Dashboard {statusBanner()}
     
    </Text>
  </Flex>

  <Text mb={8} color="green.500">
    🌱 You’ve planted <b>{function(){
      var count = 0 
      for (let i = 0; i < compStore.connected_integrations?.length; i++) {
        count += compStore.connected_integrations[i].metrics.total_trees_planted
      }
      return count

    
}()}</b> trees with Sprout across all connected integrations
  </Text>

  <SimpleGrid
    columns={{ base: "2", md: "2", lg: "2" }}
    spacing={{ base: "2", md: "6", lg: "6" }}
  >
  <Box
          p={{ base: "4", md: "8", lg: "8" }}
          bgColor="#EFFCEF"
          borderRadius="24"
        >
          <Flex>
            <Box>
              <Heading size="m">Trees Planted</Heading>
              <Text fontSize="sm" color="gray.500">
                in current period
              </Text>
            </Box>
            <Spacer />
            <Box>
              <img src="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/6413de6825deb1670ef55cb1_ic_dashTree.svg" />
            </Box>
          </Flex>
          <Text
            fontSize={{ base: "2xl", md: "2xl", lg: "3xl" }}
            mt={{ base: "2", md: "6", lg: "6" }}
          >
            {compStore.current_integration?.metrics?.total_trees_planted_in_cycle}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {compStore.current_integration?.metrics?.total_trees_planted} total
          </Text>
        </Box>
    <Box
          p={{ base: "4", md: "8", lg: "8" }}
          bgColor="#FFEFE2"
          borderRadius="24"
        >
          <Flex>
            <Box>
              <Heading size="m">Planting Costs</Heading>
              <Text fontSize="sm" color="gray.500">
                in current period
              </Text>
            </Box>
            <Spacer />
            <Box>
              <img src="https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/6413dff1be9e528533a76f47_ic_dashCost.svg" />
            </Box>
          </Flex>
          <Text
            fontSize={{ base: "2xl", md: "2xl", lg: "3xl" }}
            mt={{ base: "2", md: "6", lg: "6" }}
          >
            ${compStore.current_integration?.metrics?.total_cost_in_cycle ==0 ? "0.00" : (compStore.current_integration?.metrics?.total_cost_in_cycle/100).toFixed(2)}
          </Text>
        </Box>
       
  </SimpleGrid>
</Box>
    )


}
