import React, { useEffect } from 'react';

import firebase from '../firebase';

import {
  AlertDescription,
  AlertIcon,
  Link,
    Box,
    Flex,
    Container,
    Stack,
    Text,
    Image,
    Heading,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Radio,
    RadioGroup,
    Button,
    Badge,
    Checkbox,
    CheckboxGroup,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Alert
  } from "@chakra-ui/react";
import BillingModal from './BillingModal';
import ButtonWithDialog from './ButtonWithDialog';
import ToggleButton from './ToggleButton';
import { GetTreeCountBadgeRender, UninstallProductBadge,GetSproutProductBadgeRender, InstallProductBadge, LoadBadgeCSS, LoadModalScript, GetAccountSettings, RefreshSettings } from '../backend';
import CustomSproutButton from './CustomSproutButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';



export default function CounterBanner({compStore, setCompStore}) {
    const [user, loading] = useAuthState(firebase.auth())
    const [badgeRender, setBadgeRender] = React.useState(null)
    async function getSproutTreeCountBadgeRender() {
        await LoadBadgeCSS()
        await LoadModalScript("https://sprout-app.thegoodapi.com/app/badges/product_modal_script", compStore.current_integration.uuid)
        const badgeRender = await GetTreeCountBadgeRender(user.getIdToken(true),compStore.current_integration?.uuid)
        if (badgeRender == null) {
          setBadgeRender("<div> Setup your app settings, before seeing what the badge will look like </div>")
          return
        }





        setBadgeRender(badgeRender)
    }


    useEffect(() => {
        getSproutTreeCountBadgeRender()
    }, [compStore.current_integration])

    return (
        <>
      <Box marginLeft="5px" dangerouslySetInnerHTML={{ __html: badgeRender }} />
        </>
    )


}
