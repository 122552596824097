import React, { useState } from 'react';
import {  Link as ReactLink,Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Link,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import firebase from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {ReactComponent as SSValueProp} from './images/SqaurespaceSproutLogin.svg';
import CustomSproutButton from './CustomSproutButton';
import {getUrlParams} from '../utils';

async function handleForgetPassword(email, password, errSetter) {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      alert("Password reset email sent if account exists 🌳")
    } catch (error) {
      errSetter("Invalid username or password");
      window.setTimeout(()=>{errSetter("")}, 3000)
  
    }
  }
  

export default function ForgetPassword() {
  const location = useLocation();
  const stateData = location.state;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [user, loading] = useAuthState(firebase.auth());
  const nav = useNavigate();
  const params = getUrlParams();


  React.useEffect(() => {
    if (!loading) {
      if (user) {
        return nav("/home", {
          state:{...params, ...stateData},
         }, );      }
    }
  }, [loading, user]);



  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
<Flex flex={1} position="relative">
  <Image
    objectFit="cover"
    src="https://sprout-trees.imgix.net/standalone-login-banner.jpeg?fm=webp&auto=format&w=1000&blur=41"
  />
  <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
    <SSValueProp />
  </Box>
</Flex>


      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack w={"full"} maxW={"md"}>
            <Box>
            <Image
              mb="60px"
              h="35px"
              src={
                "https://uploads-ssl.webflow.com/6129929c4bf93b549649bfa5/6417bf52d0396940659d64d3_logo-sprout-green.svg"
              }
            />

            </Box>
            <Text mb="30px" fontSize="xl" fontWeight="medium">
              Enter your email to reset your password
            </Text>

          <Box mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              mb={4}
              bgColor="white"
              borderRadius="10"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Stack spacing={6}>
             <CustomSproutButton ignoreToast={true} color={"teal"} label="Send Reset Email"  handler={()=>{handleForgetPassword(email, setError)}} ignoreBillingModal={true} />
            {error && <Text color="red">{error}</Text>}
            <center>
              <Text>
                
                <Link as={ReactLink} state={stateData} to="/login" fontWeight="medium" color={"teal.500"}>
                  Login
                </Link>
              </Text>
            </center>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
}
