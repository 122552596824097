import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  createHashRouter
} from "react-router-dom";
import "./index.css";

// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import {Home} from "./components/home";
import { CreateAccount } from "./components/CreateAccount";
import Login from "./components/login";
import OauthConnect from "./components/oauth";
import Billing from "./components/Billing";
import InitiateSS from "./components/initiateSS";
import ForgetPassword from "./components/forgetpassword";


const basePath = "";
const router = createBrowserRouter([
  {
    path: `${basePath}`,
    element:<CreateAccount/>
    
  },
  {
    path: `${basePath}/login`,
    element:<Login/>
  },
  {
    path: `${basePath}/signup`,
    element:<CreateAccount />
  },
  {
    path: `${basePath}/home`,
    element:<Home />
  },

  {
    path: `${basePath}/squarespace/connect`,
    element: <OauthConnect redirectPath="/api/squarespace/connect" integrationId="squarespace"/>
  },

  {
    path: `${basePath}/squarespace/initiate`,
    element: <InitiateSS/>
  },
  {
    path: `${basePath}/forget_password`,
    element: <ForgetPassword/>
  }

]);
 
ReactDOM.createRoot(document.getElementById("root")).render(
  <ChakraProvider>
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  </ChakraProvider>

);