import React from 'react';
import firebase from '../firebase';
import {BiCreditCardFront} from "react-icons/bi"
import {IoLogOutOutline} from "react-icons/io5"

import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Heading,
  Text,
  HStack,
  SimpleGrid
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {ReactComponent as SproutLogo} from './images/sprout-logo.svg';
import {ReactComponent as VerticalLine} from './images/vert-line.svg';
import {ReactComponent as SSLogo} from './images/ss-logo.svg';
import { useAuthState } from 'react-firebase-hooks/auth'; 
import { Navigate } from 'react-router-dom';
import BillingModal from './BillingModal';
import { HTTPGet } from '../httputils';
import { GetAddIntegrationURL } from '../backend';

 
const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}>
    {children}
  </Link>
);

export default function NavBar({compStore ,setCompStore}) {
  const logout = () => {
    window.$crisp.push(["do", "session:reset", [true]])
    window.history.replaceState({}, document.title)
    firebase.auth().signOut();

    return <Navigate to="/login" />;
  }

  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [showBillingModal, setShowBillingModal] = React.useState(false);
  const [user, loading] = useAuthState(firebase.auth());
  const col = useColorModeValue('white', 'gray.900')
  if (!user && !loading) {
    return <Navigate to="/login" />;
  }
  return (
    <>
    <BillingModal show={showBillingModal} setShow={setShowBillingModal} compStore={compStore} setCompStore={setCompStore}/>
      <Box bg={col} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <Flex>
                <Box><SproutLogo/></Box>
                <Center pl="24px"><VerticalLine/></Center>
                <Menu>
                <Center pl="24px" ><SSLogo/></Center>
                    <MenuButton> <Center  pl="6px"as='span'><Text fontSize={"20px"}>{
                    function(){
                        if (compStore.current_integration == null || Object.keys(compStore.current_integration).length == 0 ) {
                            return "+ Add Integration"
                        }else {
                            return compStore.current_integration.human_name
                        }
                    }()
                    
                    }</Text></Center></MenuButton>
                    {
                  <MenuList>
                  {
                    (() => {
                      const integrations = [];
                      for (let i = 0; i < compStore.connected_integrations.length; i++) {
                        const selected_int = compStore.connected_integrations[i];
                        
                        if (compStore.current_integration?.uuid !== selected_int.uuid) {
                          integrations.push(
                            (() => {
                              const int = selected_int;

                              return (
                                <MenuItem
                                  onClick={() => {
                                    setCompStore({
                                      ...compStore,
                                      selectedIntegration: int,
                                      current_integration: int,
                                      selected_integration: int,

                                    });
                                  }}
                                >
                                  <Text fontSize="sm">{int.human_name}</Text>
                                </MenuItem>
                              );
                            })()
                          );
                        }
                      }
                
                      integrations.push(
                        <MenuItem
                          onClick={async () => {
                           var url = await GetAddIntegrationURL(user.getIdToken(true), "squarespace");
                            window.location.href = url
                          }}
                        >
                          <Text fontSize="sm">+ Add Integration</Text>
                        </MenuItem>
                      );
                      
                      return integrations;
                    })()
                  }
                </MenuList>
                
          }


                
                </Menu>
        </Flex>

            <Flex alignItems={'center'}>
            <Box>
                <SimpleGrid columns={2} >
                <Center pl="40px" pt={"5px"} >
                  <Link style={{ display: "flex", alignItems: "center" }} onClick={ () => { setShowBillingModal(true) } }>
                  <BiCreditCardFront style={{
                    color: "gray",
                    fontSize: "30px",
                  }} ></BiCreditCardFront>
                  <Text color={"gray"} fontSize={"20px"} pl={"10px"}>Billing</Text>
                  </Link>
                </Center>
                <Center pl="40px" pt={"5px"} >
                <Link style={{ display: "flex", alignItems: "center" }} onClick={ () => { logout() } }>
                  <IoLogOutOutline style={{
                    color: "gray",
                    fontSize: "30px",
                  }} ></IoLogOutOutline>
                  <Text color={"gray"} fontSize={"20px"} pl={"10px"}>Logout</Text>
                  </Link>
                </Center>
                </SimpleGrid>
                
                </Box>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}